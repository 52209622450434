import { useState, useEffect } from 'react'
import { fetchRedirect } from './redirect_api'

export const getRedirectOptionsByLocation = (location: Location) => {
  return {
    path: location.pathname,
    host: location.host,
    search: location.search,
  }
}

export const useRedirectData = (options: any) => {
  const [redirect, setRedirect] = useState(undefined)

  useEffect(() => {
    fetchRedirect(options).then(response => {
      setRedirect(response.data)
    })
  }, [options.path + '-' + options.host + '-' + options.search])

  return redirect
}
