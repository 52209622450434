import React from 'react'
import Layout from '../components/layout/Layout'
import SEO from '../components/generic/Seo'
import { isBrowser } from '../utils/utils'
import withLocation from '../components/generic/withLocation'
import {
  useRedirectData,
  getRedirectOptionsByLocation,
} from '../lib/global/redirect_hook'
import { LinearProgress } from '@mui/material'
import { navigate , Link } from 'gatsby'
import { trackEvent } from '../utils/ArtboxoneTracking'
import {env} from '../../environment'
import { useTranslation } from 'react-i18next'
import fetchError from '../lib/global/error_api'
import CmsBlock from '../components/cms/CmsBlock'
import BundleOverview from '../components/products/BundleOverview'

const NotFoundPage = (options: any) => {
  if (!isBrowser()) {
    return <></>
  } else {
    const { t } = useTranslation('translation')
    const redirectInfo: any = useRedirectData(
      getRedirectOptionsByLocation(options.location),
    )

    if (redirectInfo === undefined) {
      return (
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <LinearProgress />
            </div>
          </div>
        </div>
      )
    } else if (redirectInfo.location != '') {
      if (process.env.NODE_ENV === `development`) {
        return (
          <div>
            <h1>Umleitung [DEV]</h1>
            <Link to={redirectInfo.location}>{redirectInfo.location}</Link>
          </div>
        )
      } else {
        navigate(redirectInfo.location)
        return <></>
      }
    } else {
      trackEvent({
        category: 'error',
        action: 'page_not_found',
        label: options.location.pathname,
      })

      return (
        <Layout>
          <SEO title="404: Not found" />

          <div className="container">
            <div className="row">
              <CmsBlock blockId="404_page" />
            </div>
          </div>

          <BundleOverview
            base_url={options.pageContext.base_url}
            bundleId="554"
            key="404bundle"
            page="1"
          />
        </Layout>
      )
    }
  }
}

export default withLocation(NotFoundPage)
