import {env} from '../../../environment'

export const fetchBundleData = (options: any) => {
  let language = env.getLanguage()
  if (env.getCountry() == 'CH') {
    language = 'ch'
  }

  let url =
    env.getServiceHost() +
    'products/productsByBundle?version=6&bundleId=' +
    options.bundleId +
    '&language=' +
    language

  if (env.getCountry() == 'GB') {
    url = url + '&country=' + env.getCountry()
  }

  if (options.page > 1) {
    url = url + '&page=' + options.page
  }

  if (
    typeof options.removeDuplicates === 'boolean' &&
    options.removeDuplicates === true
  ) {
    url = url + '&removeDuplicates=1'
  }

  return fetch(url).then((response) => response.json())
}
